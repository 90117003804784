import { get } from 'lodash'
import { GetDepositConfig, GetDepositPayment } from '@/api/deposit'
import {
  FindReservationParameter,
  GetReservationCheckoutPaymentConfig,
  GetReservationCheckoutConfig,
  GetReservationCheckoutInvoiceConfig,
} from '@/api/reservation'
import { useFetch } from '@/use/fetch'
import { reactive, computed } from 'vue'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'

const featureKeys = {
  useDeposit: 'adminView.appointmentDeposit.enable',
  useClassTicket: 'admin.classTicket.page',
  useCheckoutConfig: 'admin.appointmentCheckoutConfig.page',
  useCheckoutPayment: 'admin.appointmentCheckoutPaymentConfig.page',
  useCheckoutInvoice: 'admin.appointmentCheckoutInvoiceConfig.page',
  useOrderCashbackDiscount: 'adminView.appointmentOrder.checkoutCashbackDeduction',
}

export const useReservation = () => {
  const { simpleFetch } = useFetch()
  const { shopId } = useShop()
  const { checkAction } = usePermissions()

  const chargeType = computed(() => get(configData.reservation, 'chargeType'))

  const useFeatures = computed(() => {
    return {
      deposit: checkAction(featureKeys.useDeposit),
      serviceClassTicket: checkAction(featureKeys.useClassTicket),
      checkoutConfig: checkAction(featureKeys.useCheckoutConfig),
      checkoutPayment: checkAction(featureKeys.useCheckoutPayment),
      checkoutInvoice: checkAction(featureKeys.useCheckoutInvoice),
      cashbackDiscount: checkAction(featureKeys.useOrderCashbackDiscount),
    }
  })

  const configData = reactive({
    deposit: {},
    depositPayments: [],
    checkout: {},
    checkoutPayments: [],
    checkoutInvoice: {},
    reservation: {},
  })

  // enabled block
  const enabledBlocks = computed(() => ({
    deposit: enabledDepositBlock.value,
    serviceClassTicket: enabledServiceClassTicketBlock.value,
  }))
  const enabledDepositBlock = computed(() => {
    if (!get(configData.deposit, 'enable')) return false
    if (!checkAction(featureKeys.useDeposit)) return false
    return true
  })

  const enabledServiceClassTicketBlock = computed(() => {
    // if (!get(configData.deposit, 'enableClassTicketCredit')) return false
    if (!checkAction(featureKeys.useClassTicket)) return false
    return true
  })

  // show block
  const showBlocks = computed(() => ({
    deposit: showDepositBlock.value,
    serviceClassTicket: showServiceClassTicketBlock.value,
  }))
  const showDepositBlock = computed(() => {
    if (!enabledDepositBlock.value) return false
    return true
  })

  const showServiceClassTicketBlock = computed(() => {
    if (!enabledServiceClassTicketBlock.value) return false
    return true
  })

  const showCheckoutBlock = computed(() => {
    const chargeType = get(configData.reservation, 'chargeType')
    // const enabledCheckout = get(configData.checkout, 'enable')
    if (chargeType !== 'checkout') return false
    // if(!enabledCheckout) return false
    return true
  })

  const showInvoiceBlock = computed(() => {
    const enableInvoice = get(configData.checkoutInvoice, 'enable')
    const chargeType = get(configData.reservation, 'chargeType')
    if (chargeType !== 'checkout') return false
    if (enableInvoice) return true
    return false
  })

  const getConfig = async (key) => {
    const basePayload = { shopId: shopId.value }

    if (key === 'deposit') {
      if (!checkAction(featureKeys.useDeposit)) return
      simpleFetch(GetDepositConfig, basePayload, (res) => {
        configData.deposit = res
      })
    } else if (key === 'depositPayments') {
      if (!checkAction(featureKeys.useDeposit)) return
      simpleFetch(GetDepositPayment, basePayload, (res) => {
        configData.depositPayments = res
      })
    } else if (key === 'reservation') {
      const res = await FindReservationParameter(basePayload)
      configData.reservation = res
    } else if (key === 'checkout') {
      if (!checkAction(featureKeys.useCheckoutConfig)) return
      simpleFetch(GetReservationCheckoutConfig, basePayload, (res) => {
        configData.checkout = res
      })
    } else if (key === 'checkoutPayments') {
      if (!checkAction(featureKeys.useCheckoutPayment)) return
      simpleFetch(GetReservationCheckoutPaymentConfig, basePayload, (res) => {
        configData.checkoutPayments = res
      })
    } else if (key === 'checkoutInvoice') {
      if (!checkAction(featureKeys.useCheckoutInvoice)) return
      simpleFetch(GetReservationCheckoutInvoiceConfig, basePayload, (res) => {
        configData.checkoutInvoice = res
      })
    }
  }

  const getAllConfig = async () => {
    const basePayload = { shopId: shopId.value }
    const callList = []

    if (checkAction(featureKeys.useDeposit)) {
      callList.push(async () => {
        simpleFetch(GetDepositConfig, basePayload, (res) => {
          configData.deposit = res
        })
      })
      callList.push(async () => {
        simpleFetch(GetDepositPayment, basePayload, (res) => {
          configData.depositPayments = res
        })
      })
      // simpleFetch(GetDepositConfig, basePayload, (res) => {
      //   configData.deposit = res
      // })
      // simpleFetch(GetDepositPayment, basePayload, (res) => {
      //   configData.depositPayments = res
      // })
    }
    if (checkAction(featureKeys.useCheckoutConfig)) {
      callList.push(async () => {
        simpleFetch(GetReservationCheckoutConfig, basePayload, (res) => {
          configData.checkout = res
        })
      })
      // simpleFetch(GetReservationCheckoutConfig, basePayload, (res) => {
      //   configData.checkout = res
      // })
    }
    if (checkAction(featureKeys.useCheckoutPayment)) {
      callList.push(async () => {
        simpleFetch(GetReservationCheckoutPaymentConfig, basePayload, (res) => {
          configData.checkoutPayments = res
        })
      })

      // simpleFetch(GetReservationCheckoutPaymentConfig, basePayload, (res) => {
      //   configData.checkoutPayments = res
      // })
    }
    if (checkAction(featureKeys.useCheckoutInvoice)) {
      callList.push(async () => {
        simpleFetch(GetReservationCheckoutInvoiceConfig, basePayload, (res) => {
          configData.checkoutInvoice = res
        })
      })
      // simpleFetch(GetReservationCheckoutInvoiceConfig, basePayload, (res) => {
      //   configData.checkoutInvoice = res
      // })
    }

    callList.push(async () => {
      const res = await FindReservationParameter(basePayload)
      configData.reservation = res
    })

    // const res = await FindReservationParameter(basePayload)
    // configData.reservation = res

    await Promise.allSettled(callList.map((call) => call()))
  }

  return {
    getAllConfig,
    showDepositBlock,
    showServiceClassTicketBlock,
    configData,
    showBlocks,
    enabledBlocks,
    getConfig,
    showInvoiceBlock,
    showCheckoutBlock,
    useFeatures,
    chargeType,
  }
}
